.not-found-octocat-wrapper {
  width: 71px;
  height: 71px;
  // stylelint-disable-next-line primer/borders
  border-radius: 45px;

  &::after {
    position: absolute;
    // stylelint-disable-next-line primer/spacing
    top: 58px;
    // stylelint-disable-next-line primer/spacing
    left: 45px;
    z-index: -2;
    display: block;
    width: 4px;
    height: 4px;
    vertical-align: baseline;
    content: '';
    background: var(--bgColor-default, var(--color-canvas-default));
    // stylelint-disable-next-line primer/borders
    border-radius: $border-radius-1;
    // stylelint-disable-next-line primer/box-shadow
    box-shadow: 0 4px 0 #fff, 0 8px 0 #fff, 0 12px 0 #fff, 0 16px 0 #fff, 0 20px 0 #fff;
    animation-name: pull-string;
    animation-duration: 0.75s;
    animation-fill-mode: forwards;
    animation-delay: 0.5s;
  }
}

@keyframes lightbulb {
  0%,
  8%,
  14% {
    opacity: 0.1;
  }

  10%,
  25% {
    opacity: 0.25;
  }

  5%,
  30%,
  50%,
  70% {
    opacity: 0.5;
  }

  16%,
  60%,
  80% {
    opacity: 0.75;
  }

  90% {
    opacity: 0.8;
  }

  94% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
    // stylelint-disable-next-line property-no-unknown
    stddeviation: 0;
  }
}

.not-found-lightbulb-ani {
  z-index: 1;
  opacity: 0.25;
  animation-name: lightbulb;
  animation-duration: 2.5s;
  animation-fill-mode: forwards;
  animation-delay: 1.3s;
}

@keyframes pull-string {
  50% {
    transform: translate3d(0, 12px, 0);
  }

  75% {
    opacity: 1;
    transform: none;
  }

  100% {
    opacity: 0;
  }
}
